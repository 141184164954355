import { Show, batch, createEffect, createRenderEffect, createSignal, on, splitProps, untrack } from "solid-js";
import { FormControlData, FormControlValue, InputFileElementViewerProps, InputFileViewProps } from "../model";
import { createFormControl } from "solid-forms";
import { Atom } from ":mods";
import { ClearInput } from "../elements/clear-input";
import { getFileString } from "../helpers";
import { unwrap } from "solid-js/store";

export function InputFile<D extends FormControlData>(props: InputFileViewProps<D>) {
  const [local, others] = splitProps(props, [
    "control",
    "onChange",
    "update",
    "classes",
    "placeholder",
    "elements",
    "accept",
  ]);
  let input_ref: HTMLInputElement = undefined;
  const _control = Array.isArray(local.control) ? createFormControl<FileList, D>(...local.control) : local.control;
  const [$viewer_data, set_viewer_data] = createSignal({
    placeholder: typeof _control.value === "string" ? _control.value : local.placeholder,
  } as InputFileElementViewerProps);
  const [dragging, setDragging] = createSignal(false);
  // @ts-ignore
  const dragNDropEvents$ = Atom.Directive.method.dragNDropEvents$;
  const onInputClicked = () => {
    input_ref?.click();
  };
  const onSelectFiles = (files: FileList) => {
    let in_progress_files = 0;
    let ready_files = 0;
    const buffers = [];
    for (const file of files) {
      in_progress_files++;
      getFileString(file, (buffer) => {
        buffers.push(buffer);
        ready_files++;
        if (ready_files === in_progress_files) {
          batch(() => {
            set_viewer_data({ files, buffers, placeholder: buffers[0], file: files[0] });
            local.onChange?.(files);
          });
        }
      });
    }
  };

  function setValue(files: FileList) {
    batch(() => {
      //! KEEP SETTING TO UNDEFINED FIRST BEFORE SETTING ACTUAL VALUE TO AVOID UN-UPDATED VALUE
      _control.setValue(undefined);
      _control.setValue(files);
    });
  }
  createRenderEffect(() => {
    const value = _control.value;
    // console.log("input file :: ", " :: ", unwrap(value));
    if (value === undefined || value === null) {
      if ($viewer_data()?.files) {
        set_viewer_data((s) => ({ files: null, file: null, buffers: null, placeholder: local.placeholder }));
      }
    } else if (typeof value === "string") {
      // incase just updating value with a string maybe via api fetch
      set_viewer_data((s) => ({ ...s, placeholder: value }));
      _control.setValue(undefined);
    } else {
      // checking actual files value
      onSelectFiles(value);
    }
  });
  return (
    <div
      use:dragNDropEvents$={{
        onEnter: () => setDragging(true),
        onLeave: () => setDragging(false),
        onDrop: (e) => setValue(e.dataTransfer.files),
      }}
      class={`relative flex overflow-hidden group input-file-container ${local.classes?.container ?? ""}`}
      classList={{
        invalid: !!_control.errors,
        touched: _control.isTouched,
        disabled: _control.isDisabled,
        required: _control.isRequired,
      }}
    >
      {/* TODO: add props to input */}
      <input
        {...others}
        ref={input_ref}
        type="file"
        class="!hidden"
        onChange={(e) => setValue(e.target.files)}
        onblur={() => _control.markTouched(true)}
        onfocus={() => _control.markTouched(true)}
        onfocusin={() => _control.markTouched(true)}
        onfocusout={() => _control.markTouched(false)}
        disabled={_control.isDisabled}
        required={_control.isRequired}
        accept={local.accept ?? "*"}
      />
      {local.elements?.viewer && local.elements?.viewer?.($viewer_data())}
      <div
        onclick={local.elements?.input ? undefined : onInputClicked}
        // onclick={onInputClicked}
        class={`
          absolute top-0 flex min-w-full min-h-full !z-10  items-center justify-center text-center 
          ${!local.elements?.viewer ? "" : " invisible group-hover:!visible "}
          ${dragging() ? " !visible " : ""}
          ${local.elements?.input ? "" : " cursor-pointer "}
          input
          ${local.classes?.input ?? ""}
          `}
      >
        <Show
          when={!local.elements?.input}
          fallback={local.elements?.input?.({
            onInputClicked,
            ref: input_ref,
            drag: dragging(),
            viewer_data: $viewer_data(),
          })}
        >
          <span class={`relative w-full h-full text-16px capitalize input-text ${local.classes?.input_text ?? ""}`}>
            <Show
              when={!local.elements?.input_text}
              fallback={
                <Show
                  when={typeof local.elements?.input_text === "string"}
                  fallback={(local.elements as any)?.input_text({ ...$viewer_data(), drag: dragging() })}
                >
                  {local.elements?.input_text as any}
                </Show>
              }
            >
              <Show when={!$viewer_data()?.buffers} fallback={$viewer_data().file.name}>
                {!dragging() ? "choose file" : "Drop Files Here"}
              </Show>
            </Show>
          </span>
        </Show>
      </div>
      {/* <ClearInput /> */}
      {/* <div ondragenter={onDragEnter} class={`relative  ${local.classes?.input ?? ""}`}></div> */}
      {/* <div class="flex flex-col justify-center items-center gap-5px">
        <i class="icon-local:upload-icon w-29.5px h-19.6px" />
        <p>
          {title()}
          {!dragging() && (
            <span class="underline ms-2px cursor-pointer" onClick={onClickUpload}>
              choose a file
            </span>
          )}
        </p>
      </div> */}
    </div>
  );
}
